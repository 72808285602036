<template>
    <div>
        <div class="frm" ref="load">
            <el-table :data="list" style="width:100%">
                <el-table-column prop="user_name" label="姓名">
                </el-table-column>
                <el-table-column prop="user_mobile" label="手机号">
                </el-table-column>
                <el-table-column prop="created_at" label="操作日期">
                    <template slot-scope="scope">
                        <div>{{scope.row.created_at | moment("YYYY/MM/DD")}}</div>
                    </template>
                </el-table-column>
                <el-table-column prop="cert_stat" label="授权状态" width="270px">
                    <template slot-scope="scope">
                        <span>《</span>
                        <span v-if="scope.row.seal_type==1">设计作品版权出售合同</span>
                        <span v-if="scope.row.seal_type==2">商品打样合同</span>
                        <span v-if="scope.row.seal_type==3">代加工合同</span>》
                        <span v-if="scope.row.op==0">保留</span>
                        <span v-if="scope.row.op==1" style="color:#2BBAAE">授权</span>
                        <span v-if="scope.row.op==2" style="color:#F66F6A">取消授权</span>
                    </template>
                </el-table-column>
                <el-table-column prop="op_user_name" label="操作人">
                </el-table-column>
            </el-table>
        </div>
        <div class="page flex">
            <div class="flex-1">
                <el-pagination background layout="prev, pager, next" :current-page.sync="queryOpt.page" :page-size="queryOpt.pagesize" :total="total"></el-pagination>
            </div>
        </div>
        <div class="flex flex-justify-around" style="padding: 30px 140px 0;">
            <el-button @click="hide()" style="width:76px;" size="small" type="primary" plain>取消</el-button>
            <el-button @click="hide()" style="width:76px;" size="small" type="primary">确定</el-button>
        </div>
    </div>
</template>

<script>
    import { debounce } from 'lodash';
    import { listSealLog } from "@/service/contract"
    export default {
        props:['form'],
        data() {
            return {
                queryOpt: {
                    seal_type:1, // 印章类型，0保留，1设计作品版权出售合同章，2商品打样合同章，3代加工合同章
                    page:1,
                    pagesize:10,
                },
                total: 10,
                list: [],
            }
        },
        watch: {
            'queryOpt.page': {
                handler() {
                    this.debounceRefreshItems();
                },
                deep: true
            },
        },
        created() {
            this.queryOpt.seal_type = this.form.seal_type;
            this.debounceRefreshItems = debounce(this.refreshItems, 500);
            this.refreshItems();
        },
        mounted() {},
        methods: {
            goto(path) {
                this.$router.push(path);
            },
            refreshItems() {
                const _loading = this.$loading({
                    target:this.$refs.load,
                    lock: true,
                    text: "拼命加载中....",
                });
                listSealLog(this.queryOpt).then(rst=>{
                    this.total=rst.total;
                    this.list = rst.list;
                    console.log(this.list);
                    _loading.close();
                }).catch(err => {
                    _loading.close();
                    console.log(err);
                    this.$message.error(err.message);
                });
            },
            hide() {
                this.$emit('hide');
            },
        }
    }
</script>
<style scoped>
    .frm {
        height:480px;
        overflow:auto;
    }
    div.frm>>> .el-table {
        border-top: 1px solid #EBEEF5;
        border-left: 1px solid #EBEEF5;
        border-right: 1px solid #EBEEF5;
    }
    div.frm>>> .columnClass {
        color: #CCCCCC;
    }
    div.frm>>> .cell {
        text-align: center;
    }
    .page {
        padding:10px;
        border:1px solid #EBEEF5;
    }
</style>